// color variants
@import "themes-vars.module.scss";

// third-party
@import "~react-perfect-scrollbar/dist/css/styles.css";

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }

  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;

    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }

  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }

  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-10px);
  }

  75% {
    transform: translateY(10px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #101010;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #333;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#react-doc-viewer {
  background-color: #000;
  padding: 0;

  #header-bar {
    background-color: #000;
    padding: 0;

    #file-name {
      color: #fff;
      margin: 0;
    }

    #doc-nav-info {
      color: #fff;
    }
  }

  #proxy-renderer {
    background-color: #000;

    #image-renderer {
      background-color: #000;

      img {
        max-width: 100%;
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  background-color: #4a494b;
  border: 1px solid #4a4a4a;

  .ck-button {
    color: #fff;
    background-color: #4a494b;
    cursor: pointer;
  }

  .ck-button:hover {
    background-color: #fbc34a;
  }
}

.ck-editor__main {
  background-color: #000;

  .ck-editor__editable_inline {
    background-color: #000 !important;
  }

  .ck-focused {
    border: 1px solid #fbc34a;
  }
}

.table {
  table {
    border: 1px double #b3b3b3;
    border-collapse: collapse;
    border-spacing: 0;

    td {
      border: 1px solid #bfbfbf;
      min-width: 2em;
      padding: 0.4em;
    }
  }
}

.ant-image-preview-root .ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 1);
}

.ant-image {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  .ant-image-img {
    object-fit: cover;
  }
}
.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.85);
  }
  .ant-modal-chat {
  }
  .ant-modal {
    .ant-modal-content {
      background: #101010;

      .ant-modal-header {
        background: #101010;

        .ant-modal-title {
          color: #fbc34a;
        }
      }
    }
  }
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    height: 200px !important;
    .ant-drawer-content {
      width: 30%;
      margin: 0 auto;
      border-radius: 8px 8px 0 0;
      height: 200px;
      .ant-drawer-body {
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        button {
          margin-top: 20px;
          border: 1px solid #fbc34a;
          padding: 8px 40px;
        }
      }
    }
  }
}

.player-mp3 {
  height: 50px !important;
  width: 350px !important;
}

.player {
  width: 340px !important;
  height: 200px !important;
}

.ant-input {
  background-color: #555;
  border-color: #555;
  color: #fff;
}

.ant-input:hover {
  border-color: #fbc34a;
}

.ant-input:focus {
  border-color: #fbc34a;
}

@media (max-width: 900px) {
  .ant-image {
    max-width: 300px;
  }
  .ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        width: 45%;
      }
    }
  }
}

@media (max-width: 600px) {
  .ant-image {
    max-width: 200px;
  }
  .ant-drawer {
    .ant-drawer-content-wrapper {
      .ant-drawer-content {
        width: 60%;
      }
    }
  }
}
